import { PrivateRoute } from "./components/PrivateRoute";
import { Routes, Route } from "react-router-dom";
import { NotFoundPage } from "./pages/404/NotFoundPage";
import { createTheme } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { HomePage } from "./pages/HomePage/HomePage.js";
import { LoginPage } from "./pages/Login/LoginPage.js";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
