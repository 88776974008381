export const OPERATORS = [
  {
    id: 1,
    name: "operator",
  },
  {
    id: 15101115,
    name: "redramka",
  },
  {
    id: 10102040,
    name: "arabicbet",
  },
  {
    id: 10121059,
    name: "goodwin365",
  },
  {
    id: 20121353,
    name: "aladin216",
  },
  {
    id: 12100855,
    name: "localop",
  },
  {
    id: 20121752,
    name: "platform",
  },
  {
    id: 15041521,
    name: "koora365",
  },
];
