export const fetchTransactionTypes = () => {
  return fetch(`${process.env.REACT_APP_SERVER}/api/transtypes`, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((e) => {
      return { status: "error", message: `Transaction Types: ${e.message}` };
    });
};

export function fetchTransactions(filterAndSortParams) {
  return fetch(`${process.env.REACT_APP_SERVER}/api/transactions`, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(filterAndSortParams),
  })
    .then((res) => {
      if (res.status !== 200) throw new Error("Unauthorized");
      return res.json();
    })
    .then((json) => json)
    .catch((e) => {
      return { status: "error", message: `Transactions: ${e.message}` };
    });
}
