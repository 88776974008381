import React, { useEffect, useReducer, useState } from "react";
import {
  homePageReducer as reducer,
  initialFilterAndSortState,
} from "./helpers";
import { fetchTransactions, fetchTransactionTypes } from "./api";
import { SelectFilter } from "../../components/SelectFilter";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { DatePickerMoment } from "../../components/DatePickerMoment";
import { getUser } from "../../api/auth.api";
import { OPERATORS } from "../../constants/operators";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { operatorIdToName } from "../../utils/utils.js";
import IconButton from "@mui/material/IconButton";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useTheme } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";

function addSameGameAttr(array) {
  let prevValue = "";
  let flag = 0;
  array.forEach((row) => {
    if (prevValue !== row.gameId) flag = !flag;
    row.game = Number(flag);
    prevValue = row.gameId;
  });
}

export const HomePage = () => {
  const theme = useTheme();

  const [state, dispatch] = useReducer(reducer, initialFilterAndSortState);
  const [error, setError] = useState("");
  const [opId, setOpId] = useState(null);

  const [transactionsList, setTransactionsList] = useState([]);
  const [totals, setTotals] = useState(null);
  const [transTypes, setTransTypes] = useState([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setError("");

      const tableData = await fetchTransactions(initialFilterAndSortState);
      if (tableData.status === "ok") {
        addSameGameAttr(tableData.data.transactions);
        setTransactionsList(tableData.data.transactions);
        setTotals(tableData.data.totals);
      } else {
        setError(tableData.message);
        return;
      }

      const transTypes = await fetchTransactionTypes();
      if (transTypes.status === "ok") {
        setTransTypes(transTypes.types.map((t) => ({ id: t, name: t })));
      } else {
        setError(transTypes.message);
      }
    };

    fetchInitialData();

    const user = getUser();
    if (user) {
      setOpId(user.opId);
    }
  }, []);

  const handleSearch = async () => {
    setError("");
    const tableData = await fetchTransactions(state);
    if (tableData.status === "ok") {
      dispatch({ type: "resetPageIndex" });
      addSameGameAttr(tableData.data.transactions);
      setTransactionsList(tableData.data.transactions);
      setTotals(tableData.data.totals);
    } else {
      setError(tableData.message);
    }
  };

  const onPageChange = (event, newPage) => {
    dispatch({ type: "setPageIndex", value: newPage });
  };

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={(event) => {
            onPageChange(event, 0);
          }}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={(event) => {
            onPageChange(event, page - 1);
          }}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={(event) => {
            onPageChange(event, page + 1);
          }}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={(event) => {
            onPageChange(
              event,
              Math.max(0, Math.ceil(count / rowsPerPage) - 1)
            );
          }}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  function ShowTotals({ totals }) {
    const [isShown, setIsShown] = useState(false);

    return (
      <>
        {totals && (
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">Operator</StyledTableCell>
                <StyledTableCell>Bets</StyledTableCell>
                <StyledTableCell>Unbets</StyledTableCell>
                <StyledTableCell>Wins</StyledTableCell>
                <StyledTableCell>Profit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ display: isShown ? "contents" : "none" }}>
              {totals.map(
                (row, idx) =>
                  row[0] !== 0 && (
                    <TableRow key={idx}>
                      <StyledTableCell align="right">
                        {operatorIdToName(row[0])}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row[1].toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row[2].toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row[3].toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={row[4] <= 0 ? { color: "red" } : { color: "green" }}
                      >
                        {row[4].toLocaleString()}
                      </StyledTableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StyledTableCell
                  align="right"
                  onClick={() => setIsShown(!isShown)}
                  style={{ cursor: "pointer" }}
                >
                  TOTALS:
                </StyledTableCell>
                <StyledTableFooterCell>
                  {totals[totals.length - 1][1].toLocaleString()}
                </StyledTableFooterCell>
                <StyledTableFooterCell>
                  {totals[totals.length - 1][2].toLocaleString()}
                </StyledTableFooterCell>
                <StyledTableFooterCell>
                  {totals[totals.length - 1][3].toLocaleString()}
                </StyledTableFooterCell>
                <StyledTableFooterCell
                  sx={
                    totals[totals.length - 1][4] <= 0
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {totals[totals.length - 1][4].toLocaleString()}
                </StyledTableFooterCell>
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "lg",
        margin: "0 auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        sx={{
          flex: "initial",
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <Box>
          <Box>
            <DatePickerMoment
              label="Start"
              value={state.filterStartDate}
              onChange={async (newValue) =>
                dispatch({ type: "setFilterStartDate", value: newValue })
              }
            />
            <DatePickerMoment
              label="End"
              value={state.filterEndDate}
              onChange={async (newValue) =>
                dispatch({ type: "setFilterEndDate", value: newValue })
              }
            />
          </Box>
          <Box sx={{ m: 1 }}>
            <ButtonGroup size="small" aria-label="pre filters">
              <Button onClick={() => dispatch({ type: "setFilterDatesToday" })}>
                today
              </Button>
              <Button
                onClick={() => dispatch({ type: "setFilterDatesYesterday" })}
              >
                yesterday
              </Button>
              <Button
                onClick={() => dispatch({ type: "setFilterDatesThisMonth" })}
              >
                this month
              </Button>
              <Button
                onClick={() => dispatch({ type: "setFilterDatesLastMonth" })}
              >
                last month
              </Button>
              <Button onClick={() => dispatch({ type: "resetFilterDates" })}>
                reset
              </Button>
            </ButtonGroup>
          </Box>
          <Box>
            <SelectFilter
              label="Type"
              value={state.filterType}
              list={transTypes}
              handleChange={(event) =>
                dispatch({ type: "setFilterType", value: event.target.value })
              }
            />
            {opId === 0 && (
              <SelectFilter
                label="Operator"
                value={state.filterOperatorId}
                list={OPERATORS}
                handleChange={(event) =>
                  dispatch({
                    type: "setFilterOperatorId",
                    value: event.target.value,
                  })
                }
              />
            )}
            <TextField
              id="playerId"
              label="PlayerId"
              variant="standard"
              autoComplete="off"
              sx={{ m: 1 }}
              onChange={(event) =>
                dispatch({
                  type: "setFilterPlayerId",
                  value: event.target.value,
                })
              }
            />
            <Box>
              <Button
                variant="contained"
                onClick={handleSearch}
                sx={{ m: 1, mt: 3 }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <ShowTotals totals={totals} />
        </Box>
      </Box>
      {transactionsList && (
        <TableContainer component={Paper} sx={{ flex: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">ID</StyledTableCell>
                <StyledTableCell>Timestamp</StyledTableCell>
                <StyledTableCell>Game ID</StyledTableCell>
                <StyledTableCell align="right">Type</StyledTableCell>
                <StyledTableCell align="right">Value</StyledTableCell>
                <StyledTableCell align="right">Name</StyledTableCell>
                <StyledTableCell align="right">playerId</StyledTableCell>
                <StyledTableCell align="right">opPlayerId</StyledTableCell>
                <StyledTableCell align="right">Operator</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionsList
                .slice(
                  state.pageIndex * state.pageSize,
                  state.pageIndex * state.pageSize + state.pageSize
                )
                .map((row) => (
                  <StyledTableRow
                    key={row.id}
                    sx={
                      row.game && {
                        backgroundColor: theme.palette.action.hover,
                      }
                    }
                  >
                    <StyledTableCell align="right">{row.id}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(row.dateTime).toUTCString()}
                    </StyledTableCell>
                    <StyledTableCell>{row.gameId}</StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={
                        (row.type === "winGame" && { color: "lightgreen" }) ||
                        (row.type === "declineBet" && { color: "silver" })
                      }
                    >
                      {row.type}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.value}</StyledTableCell>
                    <StyledTableCell align="right">{row.name}</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.playerId}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.inOperatorId}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {operatorIdToName(row.operatorId)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableFooter sx={{ position: "sticky", bottom: "0" }}>
              {/*<TableRow>*/}
              {/*  <StyledTableCell align="right">ID</StyledTableCell>*/}
              {/*  <StyledTableCell>Timestamp</StyledTableCell>*/}
              {/*  <StyledTableCell align="right">Type</StyledTableCell>*/}
              {/*  <StyledTableCell align="right">Value</StyledTableCell>*/}
              {/*  <StyledTableCell align="right">Name</StyledTableCell>*/}
              {/*  <StyledTableCell align="right">playerId</StyledTableCell>*/}
              {/*  <StyledTableCell align="right">opPlayerId</StyledTableCell>*/}
              {/*  <StyledTableCell align="right">Operator</StyledTableCell>*/}
              {/*</TableRow>*/}
              <TableRow sx={{ backgroundColor: "grey" }}>
                <StyledTableFooterCell colSpan={9} align="right">
                  <TablePagination
                    component="div"
                    count={transactionsList.length}
                    rowsPerPage={state.pageSize}
                    rowsPerPageOptions={[state.pageSize]}
                    page={state.pageIndex}
                    onPageChange={onPageChange}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableFooterCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    position: "sticky",
    border: 0,
    top: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
  fontSize: "1em",
  backgroundColor: theme.palette.grey["900"],
  border: 0,
}));
