import moment from "moment";

export const initialFilterAndSortState = {
  filterStartDate: null,
  filterEndDate: null,
  filterType: "",
  filterPlayerId: "",
  filterOperatorId: "",
  pageIndex: 0,
  pageSize: 12,
  rowCountLimit: 1000,
};

export function homePageReducer(state, action) {
  switch (action.type) {
    case "setFilterStartDate":
      return {
        ...state,
        filterStartDate: action.value
          ? action.value.utcOffset(0).startOf("day")
          : null,
      };
    case "setFilterEndDate":
      return {
        ...state,
        filterEndDate: action.value
          ? action.value.utcOffset(0).endOf("day")
          : null,
      };
    case "setFilterType":
      return { ...state, filterType: action.value };
    case "setFilterPlayerId":
      return { ...state, filterPlayerId: action.value };
    case "setFilterOperatorId":
      return { ...state, filterOperatorId: action.value };
    case "setPageIndex":
      return { ...state, pageIndex: action.value };
    case "resetPageIndex":
      return { ...state, pageIndex: 0 };
    case "resetState":
      return { ...initialFilterAndSortState };
    case "resetFilterDates":
      return {
        ...state,
        filterStartDate: null,
        filterEndDate: null,
      };
    case "setFilterDatesToday":
      return {
        ...state,
        filterStartDate: moment().utcOffset(0).startOf("day"),
        filterEndDate: moment().utcOffset(0).endOf("day"),
      };
    case "setFilterDatesYesterday":
      return {
        ...state,
        filterStartDate: moment()
          .utcOffset(0)
          .subtract(1, "days")
          .startOf("day"),
        filterEndDate: moment().utcOffset(0).subtract(1, "days").endOf("day"),
      };
    case "setFilterDatesThisMonth":
      return {
        ...state,
        filterStartDate: moment().utcOffset(0).startOf("month"),
        filterEndDate: moment().utcOffset(0).endOf("month"),
      };
    case "setFilterDatesLastMonth":
      return {
        ...state,
        filterStartDate: moment()
          .utcOffset(0)
          .subtract(1, "months")
          .startOf("month"),
        filterEndDate: moment()
          .utcOffset(0)
          .subtract(1, "months")
          .endOf("month"),
      };
    default:
      return state;
  }
}
