import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export function SelectFilter({ label, value, list, handleChange }) {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="filter-label">{label}</InputLabel>
      <Select
        labelId="filter-label"
        id="filter-select"
        value={value}
        onChange={handleChange}
        label={label}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {list &&
          list.map((item, idx) => (
            <MenuItem value={item.id} key={idx}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
