import Cookies from "js-cookie";
import base64 from "base64url";

export const serverLogin = (login, password) => {
  return fetch(`${process.env.REACT_APP_SERVER}/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ login, password }),
  })
    .then((res) => res.json())
    .catch((e) => ({ status: "error", message: e.message }));
};

export const getUser = () => {
  let payload = Cookies.get("ga-user");
  if (!payload) return false;

  payload = base64.decode(payload.split(".")[1]);
  try {
    const user = JSON.parse(payload);
    return user.name && user.opId >= 0 ? user : false;
  } catch (e) {
    return false;
  }
};
