import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import React from "react";

export function DatePickerMoment({ label, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField variant="standard" sx={{ m: 1 }} {...params} />
        )}
      />
    </LocalizationProvider>
  );
}
